<template>

    <div class="container mx-auto flex sm:flex-row-reverse flex-wrap items-center justify-between mt-10">
        <div class="w-full md:w-1/4 mb-3 md:ml-5 bg-white">
            <div class="border p-4 pt-4 pb-8 rounded-md">
                <div class="container mx-auto">
                    <p class="text-3xl font-bold mt-5 text-gray-500">社区版</p>
                    <p class="text-4xl font-bold mt-5">¥799/<span class="text-sm">年</span></p>
                    <ul class="list-disc ml-5 pl-5 mt-10 text-left">
                        <li class="m-5 text-2xl">面试视频 & 解析</li>
                        <li class="m-5 text-2xl">面试题库</li>
                        <li class="m-5 text-2xl">技术分享视频</li>
                        <li class="m-5 text-2xl">社区聊天交流</li>
                    </ul>
                    <button @click="xlDialog = true"
                        class="hover:bg-gray-100 w-full max-w-sm border border-black text-2xl font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline mt-5">
                        下单
                    </button>

                    <transition name="popup-fade">
                        <div v-if="xlDialog"
                            class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div class="bg-white p-6 rounded shadow-lg max-w-md w-full">
                                <p class="text-red-500 mb-10 text-2xl">请扫码备注邮箱，2小时内查收邮件。</p>
                                <div class="hidden sm:block">
                                    <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                                        <div class="basis-1/2  lg:basis-full flex justify-center">
                                            <img class="h-auto object-cover " src="/static/sq_alipay.JPG" alt="支付宝图片">
                                        </div>
                                        <div class="basis-1/2  lg:basis-full flex justify-center">
                                            <img class="h-auto object-cover" src="/static/sq_wepay.JPG" alt="微信支付图片">
                                        </div>
                                    </div>
                                </div>
                                <div class="sm:block md:hidden lg:hidden">
                                    <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                                        <div class="basis-1/2  lg:basis-full flex justify-center">
                                            <img class="h-auto object-cover " src="/static/sq_alipay.JPG"
                                                style="width:50%" alt="支付宝图片">
                                        </div>
                                        <div class="basis-1/2  lg:basis-full flex justify-center">
                                            <img class="h-auto object-cover" src="/static/sq_wepay.JPG"
                                                style="width:50%" alt="微信支付图片">
                                        </div>
                                    </div>
                                </div>

                                <button @click="xlDialog = false"
                                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4">确认</button>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>

        <div class="w-full md:w-1/4 mb-3 md:ml-5 bg-white">
            <div class="border p-4 pt-4 pb-8 rounded-md">
                <div class="container mx-auto">
                    <p class="text-3xl font-bold mt-5 text-gray-500">旗舰版</p>
                    <p class="text-4xl font-bold mt-5">¥1399/<span class="text-sm">年</span></p>
                    <ul class="list-disc ml-5 pl-5 mt-10 text-left">
                        <li class="m-5 text-2xl">社区版所有功能</li>
                        <li class="m-5 text-2xl">Github仓库开发权限</li>
                        <li class="m-5 text-2xl">一对一辅导5折</li>
                        <li class="m-5 text-2xl">社区周会</li>
                    </ul>

                    <button @click="qjDialog = true"
                        class="hover:bg-gray-800 w-full max-w-sm border border-black bg-black text-2xl font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline mt-5 text-white">
                        下单
                    </button>


                    <transition name="popup-fade">
                        <div v-if="qjDialog"
                            class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div class="bg-white p-6 rounded shadow-lg max-w-md w-full">
                                <p class="text-red-500 mb-10 text-2xl">请扫码备注邮箱，2小时内查收邮件。</p>
                                <div class="hidden sm:block">
                                    <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                                        <div class="basis-1/2  lg:basis-full flex justify-center">
                                            <img class="h-auto object-cover " src="/static/qj_alipay.JPG" alt="支付宝图片">
                                        </div>
                                        <div class="basis-1/2  lg:basis-full flex justify-center">
                                            <img class="h-auto object-cover" src="/static/qj_wepay.JPG" alt="微信支付图片">
                                        </div>
                                    </div>
                                </div>
                                <div class="sm:block md:hidden lg:hidden">
                                    <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                                        <div class="basis-1/2  lg:basis-full flex justify-center">
                                            <img class="h-auto object-cover " src="/static/qj_alipay.JPG"
                                                style="width:50%" alt="支付宝图片">
                                        </div>
                                        <div class="basis-1/2  lg:basis-full flex justify-center">
                                            <img class="h-auto object-cover" src="/static/qj_wepay.JPG"
                                                style="width:50%" alt="微信支付图片">
                                        </div>
                                    </div>
                                </div>

                                <button @click="qjDialog = false"
                                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4">确认</button>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>

        <div class="w-full md:w-1/4 mb-3 md:ml-5 bg-white">
            <div class="border p-4 pt-4 pb-8 rounded-md">
                <div class="container mx-auto">
                    <p class="text-3xl font-bold mt-5 text-gray-500">限量版</p>
                    <p class="text-4xl font-bold mt-5">¥1999/<span class="text-sm">年</span></p>
                    <ul class="list-disc ml-5 pl-5 mt-10 text-left">
                        <li class="m-5 text-2xl">旗舰版所有功能</li>
                        <li class="m-5 text-2xl">2场极海1v1训练(1年内有效)</li>
                        <li class="m-5 text-2xl">其它</li>
                    </ul>
                    <button
                        class="hover:bg-blue-400 w-full max-w-sm border border-blue-400 bg-blue-200 text-2xl font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline mt-5 text-black"
                        @click="sqDialog = true">
                        下单
                    </button>

                    <transition name="popup-fade">
                        <div v-if="sqDialog"
                            class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div class="bg-white p-6 rounded shadow-lg max-w-md w-full">
                                <p class="text-red-500 mb-10 text-2xl">请扫码备注邮箱，2小时内查收邮件。</p>
                                <div class="hidden sm:block">
                                    <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                                        <div class="basis-1/2  lg:basis-full flex justify-center">
                                            <img class="h-auto object-cover " src="/static/xl_alipay.JPG" alt="支付宝图片">
                                        </div>
                                        <div class="basis-1/2  lg:basis-full flex justify-center">
                                            <img class="h-auto object-cover" src="/static/xl_wepay.JPG" alt="微信支付图片">
                                        </div>
                                    </div>
                                </div>
                                <div class="sm:block md:hidden lg:hidden">
                                    <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                                        <div class="basis-1/2  lg:basis-full flex justify-center">
                                            <img class="h-auto object-cover " src="/static/xl_alipay.JPG"
                                                style="width:50%" alt="支付宝图片">
                                        </div>
                                        <div class="basis-1/2  lg:basis-full flex justify-center">
                                            <img class="h-auto object-cover" src="/static/xl_wepay.JPG"
                                                style="width:50%" alt="微信支付图片">
                                        </div>
                                    </div>
                                </div>

                                <button @click="sqDialog = false"
                                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4">确认</button>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
export default {
    data() {
        return {
            ultimateDialog: false,
            communityDialog: false,
            sqDialog: false,
            qjDialog: false,
            xlDialog: false
        };
    },
    methods: {
    },
};
</script>
