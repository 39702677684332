<template>

  <div class="h-screen w-screen relative bg-white flex">

    <!-- 移动端 -->
    <transition class="sm:hidden w-screen">
      <div v-if="isTreeVisible && !selectedNode" :class="{ 'w-64': isTreeVisible, 'block': isTreeVisible, 'hidden': !isTreeVisible }"
        class="bg-gray-100 overflow-y-auto">
        <TreeNav :treeData="treeData" @selectNode="onSelectNode" />
      </div>
    </transition>

    <!-- PC左侧栏导航 -->
    <transition name="slide" class="hidden sm:block">
      <div v-if="isTreeVisible" :class="{ 'w-64': isTreeVisible, 'block': isTreeVisible, 'hidden': !isTreeVisible }"
        class="bg-gray-100 overflow-y-auto">
        <TreeNav :treeData="treeData" @selectNode="onSelectNode" />
      </div>
    </transition>


      <!-- 移动端 -->
    <main v-if="selectedNode" class="flex-grow bg-gray-200 p-4 transition-width duration-300 ease-in-out sm:hidden">
      <!-- 主内容区域 -->
      <div v-if="selectedNode" class="space-y-6 mt-5 text-left lg:max-w-4xl lg:mx-auto">
        <!-- <h2 class="text-2xl font-medium">{{ selectedNode.title }}</h2> -->
        <div v-if="!content" class="text-4xl text-red-400">题库更新中，敬请期待！</div>

        <div v-else>
          <p class="text-4xl text-center">{{ selectedNode.title }}</p>
          <MdPreview :modelValue="content" />
        </div>

      </div>
      <div v-else class="flex items-center justify-center h-full">
        <p class="text-gray-500 text-xl">请选择一个节点查看内容</p>
      </div>
    </main>



    <!-- PC端 -->
    <main class="flex-grow bg-gray-200 p-4 transition-width duration-300 ease-in-out hidden sm:block">
      <!-- 主内容区域 -->
      <div v-if="selectedNode" class="space-y-6 mt-5 text-left lg:max-w-4xl lg:mx-auto">
        <!-- <h2 class="text-2xl font-medium">{{ selectedNode.title }}</h2> -->
        <div v-if="!content" class="text-4xl text-red-400">题库更新中，敬请期待！</div>

        <div v-else>
          <p class="text-4xl text-center">{{ selectedNode.title }}</p>
          <MdPreview :modelValue="content" />
        </div>

      </div>
      <div v-else class="flex items-center justify-center h-full">
        <p class="text-gray-500 text-xl">请选择一个节点查看内容</p>
      </div>
    </main>

  
      <!-- 移动端 -->
    <button @click="toggleTreeVisibilityMobile"
      class="sm:hidden fixed top-4 left-4 z-20 p-1 text-gray-600 bg-white rounded-full shadow-md focus:outline-none hover:bg-gray-100">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
      </svg>
    </button>

    <!-- pc -->
    <button @click="toggleTreeVisibility"
      class="hidden sm:block fixed top-4 left-4 z-20 p-1 text-gray-600 bg-white rounded-full shadow-md focus:outline-none hover:bg-gray-100">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
      </svg>
    </button>

  </div>


  <div class="fixed bottom-8 right-8 z-10">

    <div class="flex items-center">
      <!-- 发帖按钮 -->
      <button @click="openQuestionForm"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
        添加
      </button>
    </div>
  </div>
</template>

<script>
import TreeNav from '@/components/TreeNav.vue';
import { getQuestionDetail, getQuestionTree } from '@/openapi/question';
import { MdPreview } from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
import { ref } from 'vue';

export default {
  components: {
    TreeNav,
    MdPreview
  },
  data() {
    return {
      treeData: [],
      selectedNode: null,
      isTreeVisible: true,
      content: ref(""),
      showSearch: false,
      searchText: ''
    };
  },
  methods: {
    async fetchContent(id) {
      try {
        const res = await getQuestionDetail({ id });
        this.content = res.data.data.content;
      } catch (error) {
        console.error("Error fetching content:", error);
        this.content = ""; // 清空内容，以便显示“加急更新中”
      }
    },
    async onSelectNode(node) {
      this.selectedNode = node;
      await this.fetchContent(node.id);
    },
    toggleTreeVisibility() {
      this.isTreeVisible = !this.isTreeVisible;
    },
    openQuestionForm() {
      this.$router.push('/new-question');
    },
    performSearch() {
      console.log('搜索:', this.searchText);
      // 实现搜索逻辑
      // this.fetchPosts(this.currentPage, this.searchText)
    },
    toggleTreeVisibilityMobile() {
      this.isTreeVisible = true
      this.selectedNode = null
    }
  },
  created() {
    getQuestionTree()
      .then(response => {
        this.treeData = response.data.data;
      })
      .catch(error => console.error('Error fetching tree data:', error));
  }
};
</script>

<style scoped>
/* 添加适当的CSS样式 */
</style>
