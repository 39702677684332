import { createRouter, createWebHistory } from 'vue-router'
import home from '../components/home.vue'
import register from '../components/register.vue'
import chat from '../components/chat.vue'
import post from '../components/post.vue'
import NewPost from '../components/NewPost.vue'
import video from '../components/video.vue'
import postDetail from '../components/postDetail.vue'
import account from '../components/account.vue'
import question from '../components/question.vue'
import activity from '../components/activity.vue'
import NewQuestion from '../components/NewQuestion.vue'
import verify from '../components/verify.vue'


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/home',
            component: home
        },
        {
            path: '/register',
            component: register
        },
        {
            path: '/chat',
            component: chat
        },
        {
            path: '/post',
            component: post
        },
        {
            path: '/new-post',
            name: 'NewPost',
            component: NewPost
        },
        {
            path: '/new-question',
            name: 'NewQuestion',
            component: NewQuestion
        },
        {
            path: '/video',
            component: video
        },
        {
            path: '/postDetail',
            component: postDetail
        },
        {
            path: '/account',
            component: account
        },
        {
            path: '/question',
            component: question
        },
        {
            path: '/activity',
            component: activity
        },
        {
            path: '/verify',
            component: verify
        }
    ]
})

export default router;