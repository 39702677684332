import axios from "axios";

export async function getQuestionDetail(params) {
    return axios.get('/question/detail', {
        params
    });
}

export async function getQuestionTree() {
    return axios.get('/question/getTree');
}


export async function addQuestion(quesiton) {
    return axios.post('/question/add', quesiton)
}

export async function loadVerifyList(params) {
    return axios.get('question/verifyingList', {params})
}

export async function verify(params) {
    return axios.post('question/verify', params)
}