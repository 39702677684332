<template>


    <div class="min-h-screen flex flex-col">
        <!-- 主体内容 -->
        <main class="flex-1 flex justify-center mt-10">
            <div class="container mx-auto px-4 flex flex-col md:flex-row">
                <!-- 左侧栏 -->
                <div>
                    <div class="w-64 overflow-y-auto">
                        <div class="w-64 rounded-lg border border-gray-400 p-5">
                            <TreeNav :treeData="treeData" @selectNode="onSelectNode" />
                        </div>
                    </div>
                    <div class="p-8 max-w-md mx-auto bg-white rounded-lg border border-gray-400 m-5">
                        <h2 class="text-2xl font-bold mb-4">理解程度</h2>
                        <div v-for="(option, index) in options" :key="index" class="mb-4">
                            <label :for="`option-${index}`" class="block text-sm font-medium text-gray-700">{{
                                option.text }}</label>
                            <input :id="`option-${index}`" type="range" min="0" max="5" :value="option.score"
                                @input="updateScore(index, $event.target.value)"
                                class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer">
                            <!-- <span class="text-gray-600 text-sm">{{ option.score }}</span> -->
                        </div>
                    </div>
                </div>
                <!-- 右侧主内容区 -->
                <section class="md:w-3/4 bg-white">
                    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
                        <form @submit.prevent="saveQuestion" class="bg-white shadow-md rounded-md space-y-6 p-10">
                            <h2 class="text-2xl font-semibold text-gray-800">发布题目</h2>
                            <div>
                                <input type="text" id="title" v-model="title" required placeholder="标题"
                                    class="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2 bg-gray-50" />
                            </div>
                            <div>
                                <BitfreeMdEditor v-model:md-text="content" />
                            </div>

                            <div v-if="errorMessage" class="mt-4 p-2 bg-red-100 rounded-lg text-red-800">
                                <p>{{ errorMessage }}</p>
                                <button @click="clearErrorMessage"
                                    class="ml-2 px-2 py-1 rounded-md text-red-600 hover:text-red-700">关闭</button>
                            </div>

                            <div class="flex justify-end m-4">
                                <button type="submit"
                                    class="px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-md">保存</button>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </main>
    </div>



</template>

<script>
import BitfreeMdEditor from '../components/BitfreeMdEditor.vue';
import { addQuestion } from '@/openapi/question';
import TreeNav from '@/components/TreeNav.vue';
import { getQuestionDetail, getQuestionTree } from '@/openapi/question';


export default {
    data() {
        return {
            title: '',
            content: '',
            errorMessage: '',
            isTreeVisible: false,
            treeData: [],
            selectedNode: null,
            isTreeVisible: true,
            options: [
                { id: 1, text: '学生', score: 3 },
                { id: 2, text: '高级', score: 3 },
                { id: 3, text: '专家', score: 3 }
            ]

        };
    },
    components: {
        BitfreeMdEditor,
        TreeNav
        // MdPreview
    },
    methods: {
        async onSelectNode(node) {
            this.selectedNode = node;
        },
        updateScore(index, value) {
            this.options[index].score = parseInt(value);
        },

        clearErrorMessage() {
            this.errorMessage = '';
        },
        validateContent() {
            if (!this.content || this.content.trim() === '') {
                this.errorMessage = '内容不能为空。';
                return false;
            }

            const minLength = 10;
            if (this.content.length < minLength) {
                this.errorMessage = `内容不能少于 ${minLength} 个字符。`;
                return false;
            }

            this.errorMessage = '';
            return true;
        },
        async saveQuestion() {
            if (!this.validateContent()) {
                return;
            }
            if (!this.selectedNode) {
                this.errorMessage = "请选择一个节点创建问题"
                return;
            }

            // 弹出确认对话框
            const isConfirmed = confirm('确定要保存这篇文章吗？');
            if (isConfirmed) {
                this.performSave();
            }
        },
        async performSave() {
            try {
                const response = await addQuestion({
                    title: this.title,
                    content: this.content,
                    parentId: this.selectedNode.id,
                    remark: JSON.stringify(this.options)
                });

                if (response.data.code === 200) {
                    this.title = '';
                    this.content = '';
                    alert('题目已保存！'); // 临时提示，实际应用中应替换为实际反馈
                    this.$router.push('/'); // 发帖成功后返回主页或列表页
                } else {
                    this.errorMessage = response.data.message;
                }
            } catch (error) {
                console.error('Error saving the post:', error);
                alert('保存失败，请稍后再试。');
            }
        },
    },
    created() {
        getQuestionTree()
            .then(response => {
                this.treeData = response.data.data;
            })
            .catch(error => console.error('Error fetching tree data:', error));
    }
};
</script>


<style>
input[type="range"] {
    -webkit-appearance: none;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background-color: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
}
</style>
